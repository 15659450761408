<template>
    <section>
        <div class="container" style="margin-top:10px"><div class="row"><div class="col-12">
            <div id="accordion">
           
            <div class="card">
                <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Presentación del Estudio
                    </button>
                </h5>
                </div>
                
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <!---->
                                <div class="form-group">
                                    <label class="control-label font-weight-bold">Nombre de Proyecto:</label>
                                    <input type="text" name="nombre_proyecto" class="form-control form-control-sm" v-model="presentacion.nombre_proyecto" disabled>
                                </div>
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Fecha de Inicio:</label>
                                    <input type="date" name="fecha_inicio"  class="form-control form-control-sm" v-model="presentacion.fecha_inicio" disabled>
                                </div>
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Fecha de término:</label>
                                    <input type="date" name="fecha_termino"  class="form-control form-control-sm" v-model="presentacion.fecha_termino" disabled>
                                </div>
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Nº semanas aplicación:</label>
                                    <input type="number" name="n_semanas"  class="form-control form-control-sm" v-model="presentacion.n_semanas" disabled>
                                </div>
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Terreno:</label>
                                    <input type="text" name="terreno"  class="form-control form-control-sm" v-model="presentacion.terreno" disabled>
                                </div>
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Código del proyecto:</label>
                                    <input type="text" name="codigo"  class="form-control form-control-sm" v-model="presentacion.id" disabled>
                                </div>
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Contraparte / Cliente:</label>
                                    <input type="text" name="contraparte_cliente"  class="form-control form-control-sm" v-model="presentacion.contraparte_cliente" disabled>
                                </div>
                            </div>
                            <div class="col-6">
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Jefe de Proyectos:</label>
                                    <select class="form-control  form-control-sm" id="" v-model="presentacion.jefe_proyecto" disabled>
                                        <option disabled selected>Seleccione...</option>
                                        <option>Paulina Valenzuela</option>
                                        <option>Ana Aguilera</option>
                                    </select>
                                </div>
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Asistente Proyecto:</label>
                                    <select class="form-control  form-control-sm" id="" v-model="presentacion.asistente_proyecto" disabled>
                                        <option disabled selected>Seleccione...</option>
                                        <option>Andrés Bersezio</option>
                                    </select>
                                </div>
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Abstract / Resumen / Objetivo General:</label>
                                    <input type="text" name="abs_resumen_obj"  class="form-control  form-control-sm" v-model="presentacion.abs_resumen_obj" disabled>
                                </div>
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Características del estudio:</label>
                                    <input type="text" name="caract_estudio"  class="form-control  form-control-sm" v-model="presentacion.caract_estudio" disabled>
                                </div>
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Metodología de levantamiento de datos:</label><br>
                                    <select class="form-control  form-control-sm" id="" v-model="presentacion.metodologia" disabled>
                                        <option disabled selected>Seleccione...</option>
                                        <option>Cuantitativo</option>
                                        <option>Cualitativo</option>
                                        <option>Ambos</option>
                                    </select>
                                </div>
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Jefe de campo:</label>
                                    <select class="form-control  form-control-sm" id="" v-model="presentacion.jefe_campo" disabled>
                                        <option disabled selected>Seleccione...</option>
                                        <option>Juan Bustamante</option>
                                    </select>
                                </div>
                                <!---->
                                <div class="form-group">
                                    <label class="font-weight-bold">Responsable supervisión:</label>
                                    <select class="form-control  form-control-sm" id="" v-model="presentacion.responsable_supervision" disabled>
                                        <option disabled selected>Seleccione...</option>
                                        <option>Juan Bustamante</option>
                                        <option>Tamara Peña</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                
            </div>

            <div class="card">
                <div class="card-header" id="headingTwo">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Requerimientos Operaciones
                        </button>
                    </h5>
                </div>
                
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-5 font-weight-bold border border-dark">Cuantitativa</div>
                        <div class="col-sm-5 font-weight-bold border border-dark">Cualitativa</div>
                </div>
                <!---->
                <div class="row">
                        <div class="col-sm-2 font-weight-bold border">Metodologia</div>
                        <div class="col-sm-5 border"><!--style="background-color: skyblue"-->
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Online" v-model="operaciones.met_cuant_online" disabled>
                                <label>Online</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Telefonico" v-model="operaciones.met_cuant_telefonico" disabled>
                                <label>Telefónico</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Presencial hogares" v-model="operaciones.met_cuant_pres_hogares" disabled>
                                <label>Presencial hogares</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Presencial de punto" v-model="operaciones.met_cuant_pres_punto" disabled>
                                <label>Presencial de punto</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Presencial dirigido" v-model="operaciones.met_cuant_pres_dirigido" disabled>
                                <label>Presencial dirigido</label>
                            </div>
                        </div>
                        </div>
                        <div class="col-sm-5 border"><!--style="background-color: pink"-->
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Grupos Focales" v-model="operaciones.met_cuali_grupo_focal" disabled>
                                <label>Grupos Focales</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Entrevistas semi estructuradas" v-model="operaciones.met_cuali_entrevista" disabled>
                                <label>Entrevistas semi estructuradas</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Otros" v-model="operaciones.met_cuali_otro" disabled>
                                <label>Otros (Especifique)</label>
                            </div>
                        </div>
                        </div>
                </div>
                <!---->
                <div class="row">
                    <div class="col-sm-2 font-weight-bold border">Duración intrumentos (promedio).<br> *Registrar en minutos</div>
                    <div class="col-sm-5 border">
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Online" v-model="operaciones.dur_instru_cuant_online" disabled>
                                <label>Online</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Telefonico" v-model="operaciones.dur_instru_cuant_telefonico" disabled>
                                <label>Telefónico</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Presencial hogares" v-model="operaciones.dur_instru_cuant_pres_hogares" disabled>
                                <label>Presencial hogares</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Presencial de punto" v-model="operaciones.dur_instru_cuant_pres_punto" disabled>
                                <label>Presencial de punto</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Presencial dirigido" v-model="operaciones.dur_instru_cuant_pres_dirigido" disabled>
                                <label>Presencial dirigido</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 border">
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Grupos Focales" v-model="operaciones.dur_instru_cuali_grupo_focal" disabled>
                                <label>Grupos Focales</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Entrevistas semi estructuradas" v-model="operaciones.dur_instru_cuali_entrevista" disabled>
                                <label>Entrevistas semi estructuradas</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Otros" v-model="operaciones.dur_instru_cuali_otro" disabled>
                                <label>Otros (Especifique)</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!---->
                <div class="row">
                    <div class="col-sm-2 font-weight-bold border">Muestra esperada</div>
                    <div class="col-sm-5 border">
                    <div class="form-group">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="Online" v-model="operaciones.muestra_esperada_cuant_online" disabled>
                            <label>Online</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="Telefonico" v-model="operaciones.muestra_esperada_cuant_telefonico" disabled>
                            <label>Telefónico</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="Presencial hogares" v-model="operaciones.muestra_esperada_cuant_pres_hogares" disabled>
                            <label>Presencial hogares</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="Presencial de punto" v-model="operaciones.muestra_esperada_cuant_pres_punto" disabled>
                            <label>Presencial de punto</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="Presencial dirigido" v-model="operaciones.muestra_esperada_cuant_pres_dirigido" disabled>
                            <label>Presencial dirigido</label>
                        </div>
                    </div>
                    </div>
                    <div class="col-sm-5 border">
                    <div class="form-group">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="Grupos Focales" v-model="operaciones.muestra_esperada_cuali_grupo_focal" disabled>
                            <label>Grupos Focales</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="Entrevistas semi estructuradas" v-model="operaciones.muestra_esperada_cuali_entrevista" disabled>
                            <label>Entrevistas semi estructuradas</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="Otros" v-model="operaciones.muestra_esperada_cuali_otro" disabled>
                            <label>Otros (Especifique)</label>
                        </div>
                    </div>
                    </div>
                </div>
                <!---->
                <div class="row">
                    <div class="col-sm-2 font-weight-bold border">Selección entrevistado</div>
                    <div class="col-sm-5 border">
                        <div class="form-group">
                            <p class="font-italic">PRESENCIAL:</p>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Kish" v-model="operaciones.sele_entre_presencial_kish" disabled>
                                <label>Kish</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Cuota" v-model="operaciones.sele_entre_presencial_cuota" disabled>
                                <label>Cuota</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Dirigido" v-model="operaciones.sele_entre_presencial_dirigido" disabled>
                                <label>Dirigido</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Representante Hogar" v-model="operaciones.sele_entre_presencial_representante" disabled>
                                <label>Representante Hogar</label>
                            </div>
                            <p class="font-italic">TELEFÓNICO:</p>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Movil/dirigido" v-model="operaciones.sele_entre_telefonico_movil_dirigido" disabled>
                                <label>Movil/dirigido</label>
                            </div>
                            <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="Movil/RDD" v-model="operaciones.sele_entre_telefonico_movil_rdd" disabled>
                                    <label>Movil/RDD</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Fijo/dirigido" v-model="operaciones.sele_entre_telefonico_fijo_dirigido" disabled>
                                <label>Fijo/dirigido</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Fijo/RDD" v-model="operaciones.sele_entre_telefonico_fijo_rdd" disabled>
                                <label>Fijo/RDD (Último cumpleaños)</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 border">
                        <div class="form-group">
                            <p class="font-italic">GRUPO FOCAL:</p>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Dirigido" v-model="operaciones.sele_entre_grupfocal_dirigido" disabled>
                                <label>Dirigido</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Bola de nieve" v-model="operaciones.sele_entre_grupfocal_bolanieve" disabled>
                                <label>Bola de nieve</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Cuota" v-model="operaciones.sele_entre_grupfocal_cuota" disabled>
                                <label>Cuota</label>
                            </div><br>
                            <p class="font-italic">ENTREVISTAS:</p>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Dirigido" v-model="operaciones.sele_entre_entrevistas_dirigido" disabled>
                                <label>Dirigido</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Bola de nieve" v-model="operaciones.sele_entre_entrevistas_bolanieve" disabled>
                                <label>Bola de nieve</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Cuota" v-model="operaciones.sele_entre_entrevistas_cuota" disabled>
                                <label>Cuota</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!---->
                <div class="row">
                    <div class="col-sm-2 font-weight-bold border">Selección entrevistado</div>
                    <div class="col-sm-5 border">
                        <div class="form-group">
                            <p class="font-italic">Requiere proceso de contacto y/o seguimiento:</p>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="radioseleccionentrevistado1" id="exampleRadios1" value="si" v-model="operaciones.sele_entre_cuanti_proceso_contacto" disabled>
                                <label>Si</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="radioseleccionentrevistado1" id="exampleRadios2" value="no" v-model="operaciones.sele_entre_cuanti_proceso_contacto" disabled>
                                <label>No</label>
                            </div>
                            <p class="font-italic">Requiere programación de plataforma para proceso de contacto:</p>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="radioseleccionentrevistado2" id="exampleRadios1" value="si" v-model="operaciones.sele_entre_cuanti_programacion_plataforma" disabled>
                                <label>Si</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="radioseleccionentrevistado2" id="exampleRadios2" value="no" v-model="operaciones.sele_entre_cuanti_programacion_plataforma" disabled>
                                <label>No</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 border">
                        <div class="form-group">
                            <p class="font-italic">Requiere proceso de contacto y/o seguimiento:</p>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="radioseleccionentrevistado1B" id="exampleRadios1" value="si" v-model="operaciones.sele_entre_cuali_proceso_contacto" disabled>
                                <label>Si</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="radioseleccionentrevistado1B" id="exampleRadios2" value="no" v-model="operaciones.sele_entre_cuali_proceso_contacto" disabled>
                                <label>No</label>
                            </div>
                            <p class="font-italic">Requiere programación de plataforma para proceso de contacto:</p>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="radioseleccionentrevistado2B" id="exampleRadios1" value="si" v-model="operaciones.sele_entre_cuali_programacion_plataforma" disabled>
                                <label>Si</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="radioseleccionentrevistado2B" id="exampleRadios2" value="no" v-model="operaciones.sele_entre_cuali_programacion_plataforma" disabled>
                                <label>No</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!---->
                <div class="row">
                    <div class="col-sm-2 font-weight-bold border">Programación cuestionario</div>
                    <div class="col-sm-5 border">
                        <div class="form-group">
                            <p class="font-italic">PRESENCIAL:</p>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Survey To Go" v-model="operaciones.programa_cuestio_surveytogo" disabled>
                                <label>Survey To Go</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Papel" v-model="operaciones.programa_cuestio_papel" disabled>
                                <label>Papel</label>
                            </div>
                            <p class="font-italic">TELEFÓNICO:</p>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="CATINET" v-model="operaciones.programa_cuestio_catinet" disabled>
                                <label>CATINET</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Plataforma propia" v-model="operaciones.programa_cuestio_plataforma_propia" disabled>
                                <label>Plataforma propia</label>
                            </div>
                            <p class="font-italic">WEB:</p>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Survey Monkey" v-model="operaciones.programa_cuestio_surveymonkey" disabled>
                                <label>Survey Monkey</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="CAWI" v-model="operaciones.programa_cuestio_cawi" disabled>
                                <label>CAWI</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Plataforma propia" v-model="operaciones.programa_cuestio_plataforma_propiaweb" disabled>
                                <label>Plataforma propia</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 d-flex flex-column justify-content-center border">
                        <span class="">-</span>
                    </div>
                </div>
                <!---->
                <div class="row">
                    <div class="col-sm-2 font-weight-bold border">Tipo Supervisión</div>
                    <div class="col-sm-5 border">
                        <div class="form-group">
                            <div class="form-group">
                                <label class="font-italic">Presencial (% de supervisión):</label>
                                <input type="number" class="form-control"  v-model="operaciones.tipo_supervision_presencial" disabled>							
                            </div>
                            <div class="form-group">
                                <label class="font-italic">Telefónica (% de supervisión):</label>
                                <input type="number" class="form-control"  v-model="operaciones.tipo_supervision_telefonica" disabled>							
                            </div>
                            <div class="form-group">
                                <label class="font-italic">Supervisión Escuchas (% de supervisión):</label>
                                <input type="number" class="form-control"  v-model="operaciones.tipo_supervision_escuchas" disabled>							
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 d-flex flex-column justify-content-center border">
                        <span class="">-</span>
                    </div>
                </div>
                <!---->
                <div class="row">
                    <div class="col-sm-2 font-weight-bold border">% de supervisión cuestionario</div>
                    <div class="col-sm-5 border">
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Filtro de selección entrevistado" v-model="operaciones.porce_supervision_filtro_seleccion" disabled>
                                <label>Filtro de selección entrevistado</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="Cuestionario completo" v-model="operaciones.porce_supervision_cuestionario_completo" disabled>
                                <label>Cuestionario completo</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="% de preguntas" v-model="operaciones.porce_supervision_porce_pregunta" disabled>
                                <label>% de preguntas</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 d-flex flex-column justify-content-center border">
                        <span class="">-</span>
                    </div>
                </div>
                <!---->
                <div class="row">
                    <div class="col-sm-2 font-weight-bold border">Pretest</div>
                    <div class="col-sm-5 border">
                        <div class="form-group">
                            <p class="font-italic font-weight-bold">Entrevistas cognitivas:</p>
                            <div class="form-group">
                                <label>N° de entrevistas:</label>
                                <input type="number" class="form-control"  v-model="operaciones.pretest_n_entrevistas" disabled>							
                            </div>
                            <p class="font-italic font-weight-bold">Aplicación encuesta:</p>
                            <div class="form-group">
                                <label>N° Entrevistas Telefónicas:</label>
                                <input type="number" class="form-control"  v-model="operaciones.pretest_n_telefonicas" disabled>							
                            </div>
                            <div class="form-group">
                                <label>N° Entrevistas Presenciales (replica levantamiento oficial):</label>
                                <input type="number" class="form-control"  v-model="operaciones.pretest_n_presenciales" disabled>							
                            </div>
                            <div class="form-group">
                                <label>N° Entrevistas Presenciales (Cuota):</label>
                                <input type="number" class="form-control" v-model="operaciones.pretest_presencial_cuota" disabled>							
                            </div>
                            <div class="form-group">
                                <label>N° Entrevistas online (Cuota):</label>
                                <input type="number" class="form-control"  v-model="operaciones.pretest_online_cuota" disabled>							
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 border">
                        <p class="font-italic">GRUPO FOCAL:</p>
                        <div class="form-group">
                            <label>N° de Grupos focales:</label>
                            <input type="number" class="form-control" v-model="operaciones.pretest_n_grupfocal" disabled>							
                        </div>
                        <div class="form-group">
                            <label>N° de participantes por GF:</label>
                            <input type="number" class="form-control" v-model="operaciones.pretest_n_participantes" disabled>							
                        </div>
                        <p class="font-italic">ENTREVISTAS:</p>
                        <div class="form-group">
                            <label>N° Entrevistas:</label>
                            <input type="number" class="form-control" v-model="operaciones.pretest_pn_entrevistas" disabled>							
                        </div>
                    </div>

                </div>
                </div>
                </div>
                
            </div>


            <div class="card">
                <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Definición actividades
                    </button>
                </h5>
                </div>
                
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body">
                
                            <div class="row">
                                <div class="col-sm-3 font-weight-bold border border-dark">Definición actividades</div>
                                <div class="col-sm-3 font-weight-bold border border-dark">Responsables</div>
                                <div class="col-sm-2 font-weight-bold border border-dark">Plazo (días)</div>
                                <div class="col-sm-2 font-weight-bold border border-dark">Fecha de inicio</div>
                                <div class="col-sm-2 font-weight-bold border border-dark">Fecha de término</div>
                            </div>
                            
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Diseño instrumentos cuantitativo</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">						
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="actividades.diseño_cuanti_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="actividades.diseño_cuanti_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="actividades.diseño_cuanti_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="actividades.diseño_cuanti_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="actividades.diseño_cuanti_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.diseño_cuanti_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.diseño_cuanti_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Diseño instrumentos cualitativo</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="actividades.diseño_cuali_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="actividades.diseño_cuali_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="actividades.diseño_cuali_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="actividades.diseño_cuali_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="actividades.diseño_cuali_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.diseño_cuali_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.diseño_cuali_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Construcción de marco muestral (Base RDD)</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="actividades.constru_marcomuestral_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="actividades.constru_marcomuestral_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="actividades.constru_marcomuestral_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="actividades.constru_marcomuestral_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="actividades.constru_marcomuestral_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.constru_marcomuestral_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.constru_marcomuestral_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Programación plataforma de contacto CUANTITATIVA</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="actividades.progra_plat_cuanti_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="actividades.progra_plat_cuanti_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="actividades.progra_plat_cuanti_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="actividades.progra_plat_cuanti_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="actividades.progra_plat_cuanti_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.progra_plat_cuanti_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.progra_plat_cuanti_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Programación plataforma de contacto CUALITATIVA</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="actividades.progra_plat_cuali_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="actividades.progra_plat_cuali_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="actividades.progra_plat_cuali_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="actividades.progra_plat_cuali_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="actividades.progra_plat_cuali_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.progra_plat_cuali_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.progra_plat_cuali_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Programación cuestionario</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="actividades.programacion_cuestionario_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="actividades.programacion_cuestionario_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="actividades.programacion_cuestionario_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="actividades.programacion_cuestionario_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="actividades.programacion_cuestionario_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.programacion_cuestionario_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.programacion_cuestionario_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Definición equipo pre test/terreno oficial cuantitativo</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="actividades.def_equipo_cuanti_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="actividades.def_equipo_cuanti_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="actividades.def_equipo_cuanti_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="actividades.def_equipo_cuanti_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="actividades.def_equipo_cuanti_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.def_equipo_cuanti_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.def_equipo_cuanti_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Definición equipo pre test/terreno oficial cualitativo</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="actividades.def_equipo_cuali_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="actividades.def_equipo_cuali_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="actividades.def_equipo_cuali_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="actividades.def_equipo_cuali_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="actividades.def_equipo_cuali_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.def_equipo_cuali_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.def_equipo_cuali_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Capacitación pre test cuantitativo</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="actividades.cap_pretest_cuanti_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="actividades.cap_pretest_cuanti_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="actividades.cap_pretest_cuanti_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="actividades.cap_pretest_cuanti_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="actividades.cap_pretest_cuanti_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.cap_pretest_cuanti_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.cap_pretest_cuanti_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Capacitación pre test cualitativo</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="actividades.cap_pretest_cuali_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="actividades.cap_pretest_cuali_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="actividades.cap_pretest_cuali_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="actividades.cap_pretest_cuali_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="actividades.cap_pretest_cuali_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.cap_pretest_cuali_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="actividades.cap_pretest_cuali_fecha_termino" disabled></div>
                                </div>
                            </div>
                            
                            <!---->
                            
                            <div class="row">
                                <div class="col-sm-3 border">Ejecución pre test (aplicación y reunión con encuestadores) cuantitativo</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="segactividades.ejec_pretest_cuanti_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="segactividades.ejec_pretest_cuanti_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="segactividades.ejec_pretest_cuanti_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="segactividades.ejec_pretest_cuanti_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="segactividades.ejec_pretest_cuanti_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.ejec_pretest_cuanti_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.ejec_pretest_cuanti_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Ejecución pre test (aplicación y reunión con encuestadores) cualitativo</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="segactividades.ejec_pretest_cuali_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="segactividades.ejec_pretest_cuali_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="segactividades.ejec_pretest_cuali_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="segactividades.ejec_pretest_cuali_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="segactividades.ejec_pretest_cuali_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.ejec_pretest_cuali_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.ejec_pretest_cuali_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Base de datos pre test</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="segactividades.base_pretest_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="segactividades.base_pretest_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="segactividades.base_pretest_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="segactividades.base_pretest_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="segactividades.base_pretest_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.base_pretest_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.base_pretest_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Revisión/Corrección programación instrumentos</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="segactividades.revision_correccion_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="segactividades.revision_correccion_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="segactividades.revision_correccion_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="segactividades.revision_correccion_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="segactividades.revision_correccion_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.revision_correccion_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.revision_correccion_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Capacitación Oficial Cuantitativo</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="segactividades.cap_oficial_cuanti_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="segactividades.cap_oficial_cuanti_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="segactividades.cap_oficial_cuanti_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="segactividades.cap_oficial_cuanti_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="segactividades.cap_oficial_cuanti_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.cap_oficial_cuanti_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.cap_oficial_cuanti_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Capacitación Oficial Cualitativo</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="segactividades.cap_oficial_cuali_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="segactividades.cap_oficial_cuali_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="segactividades.cap_oficial_cuali_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="segactividades.cap_oficial_cuali_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="segactividades.cap_oficial_cuali_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.cap_oficial_cuali_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.cap_oficial_cuali_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Convocatoria y reclutamiento Grupos focales</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="segactividades.convocatoria_reclutamiento_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="segactividades.convocatoria_reclutamiento_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="segactividades.convocatoria_reclutamiento_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="segactividades.convocatoria_reclutamiento_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="segactividades.convocatoria_reclutamiento_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.convocatoria_reclutamiento_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.convocatoria_reclutamiento_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Trabajo de campo cuanti</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="segactividades.trabajo_campo_cuanti_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="segactividades.trabajo_campo_cuanti_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="segactividades.trabajo_campo_cuanti_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="segactividades.trabajo_campo_cuanti_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="segactividades.trabajo_campo_cuanti_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.trabajo_campo_cuanti_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.trabajo_campo_cuanti_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Trabajo de campo cuali</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="segactividades.trabajo_campo_cuali_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="segactividades.trabajo_campo_cuali_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="segactividades.trabajo_campo_cuali_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="segactividades.trabajo_campo_cuali_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="segactividades.trabajo_campo_cuali_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.trabajo_campo_cuali_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.trabajo_campo_cuali_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Supervisión</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="segactividades.supervision_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="segactividades.supervision_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="segactividades.supervision_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="segactividades.supervision_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="segactividades.supervision_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.supervision_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.supervision_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Creación BBDD</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="segactividades.creacion_bbdd_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="segactividades.creacion_bbdd_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="segactividades.creacion_bbdd_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="segactividades.creacion_bbdd_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="segactividades.creacion_bbdd_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.creacion_bbdd_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.creacion_bbdd_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            <div class="row">
                                <div class="col-sm-3 border">Validación BBDD</div>
                                <div class="col-sm-3 border">
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="DyA" v-model="segactividades.validacion_bbdd_dya" disabled>
                                            <label class="form-check-label">D&A</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UCC" v-model="segactividades.validacion_bbdd_ucc" disabled>
                                            <label class="form-check-label">UCC</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="UDTI" v-model="segactividades.validacion_bbdd_udti" disabled>
                                            <label class="form-check-label">UDTI</label>
                                        </div><br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" value="No aplica" v-model="segactividades.validacion_bbdd_na" disabled>
                                            <label class="form-check-label">No aplica</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="number" name=""  class="form-control" v-model="segactividades.validacion_bbdd_plazo" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.validacion_bbdd_fecha_inicio" disabled></div>
                                </div>
                                <div class="col-sm-2 border d-flex flex-column justify-content-center">
                                    <div class="form-group"><input type="date" name=""  class="form-control" v-model="segactividades.validacion_bbdd_fecha_termino" disabled></div>
                                </div>
                            </div>
                            <!---->
                            
                            
                </div>
                </div>
                
            </div>
            
            
            </div>

        </div>
        </div>
        </div>
    </section>
</template>

<script>
export default {
    name:"subidaFrop",
    data () {
            return {
                presentacion:{
                    nombre_proyecto :"",
                    fecha_inicio :'',
                    fecha_termino :'',
                    n_semanas:0,
                    terreno:'',
                    contraparte_cliente:'', 
                    jefe_proyecto:'', 
                    asistente_proyecto:'', 
                    abs_resumen_obj:'', 
                    caract_estudio:'', 
                    metodologia:'', 
                    jefe_campo:'', 
                    responsable_supervision:'', 
                    id: "", 
                },
                operaciones:{
                    met_cuant_online: '',
                    met_cuant_telefonico: '',
                    met_cuant_pres_hogares: '',
                    met_cuant_pres_punto: '',
                    met_cuant_pres_dirigido: '',
                    met_cuali_grupo_focal: '',
                    met_cuali_entrevista: '',
                    met_cuali_otro: '',

                    dur_instru_cuant_online: '',
                    dur_instru_cuant_telefonico: '',
                    dur_instru_cuant_pres_hogares: '',
                    dur_instru_cuant_pres_punto: '',
                    dur_instru_cuant_pres_dirigido: '',
                    dur_instru_cuali_grupo_focal: '',
                    dur_instru_cuali_entrevista: '',
                    dur_instru_cuali_otro: '',

                    muestra_esperada_cuant_online: '',
                    muestra_esperada_cuant_telefonico: '',
                    muestra_esperada_cuant_pres_hogares: '',
                    muestra_esperada_cuant_pres_punto: '',
                    muestra_esperada_cuant_pres_dirigido: '',
                    muestra_esperada_cuali_grupo_focal: '',
                    muestra_esperada_cuali_entrevista: '',
                    muestra_esperada_cuali_otro: '',

                    sele_entre_presencial_kish: '',
                    sele_entre_presencial_cuota: '',
                    sele_entre_presencial_dirigido: '',
                    sele_entre_presencial_representante: '',

                    sele_entre_telefonico_movil_dirigido: '',
                    sele_entre_telefonico_movil_rdd: '',
                    sele_entre_telefonico_fijo_dirigido: '',
                    sele_entre_telefonico_fijo_rdd: '',

                    sele_entre_grupfocal_dirigido: '',
                    sele_entre_grupfocal_bolanieve: '',
                    sele_entre_grupfocal_cuota: '',
                    sele_entre_entrevistas_dirigido: '',
                    sele_entre_entrevistas_bolanieve: '',
                    sele_entre_entrevistas_cuota: '',

                    sele_entre_cuanti_proceso_contacto: '',
                    sele_entre_cuanti_programacion_plataforma: '',
                    sele_entre_cuali_proceso_contacto: '',
                    sele_entre_cuali_programacion_plataforma: '',

                    programa_cuestio_surveytogo: '',
                    programa_cuestio_papel: '',
                    programa_cuestio_catinet: '',
                    programa_cuestio_plataforma_propia: '',
                    programa_cuestio_surveymonkey: '',
                    programa_cuestio_cawi: '',
                    programa_cuestio_plataforma_propiaweb: '',

                    tipo_supervision_presencial: '',
                    tipo_supervision_telefonica: '',
                    tipo_supervision_escuchas: '',

                    porce_supervision_filtro_seleccion: '',
                    porce_supervision_cuestionario_completo: '',
                    porce_supervision_porce_pregunta: '',

                    pretest_n_entrevistas: '',
                    pretest_n_telefonicas: '',
                    pretest_n_presenciales: '',
                    pretest_presencial_cuota: '',
                    pretest_online_cuota: '',
                    pretest_n_grupfocal: '',
                    pretest_n_participantes: '',
                    pretest_pn_entrevistas: '',
                },
                actividades:{
                    diseño_cuanti_dya:'',
                    diseño_cuanti_ucc:'',
                    diseño_cuanti_udti:'',
                    diseño_cuanti_na:'',
                    diseño_cuanti_plazo:0,
                    diseño_cuanti_fecha_inicio:'',
                    diseño_cuanti_fecha_termino:'',

                    diseño_cuali_dya:'',
                    diseño_cuali_ucc:'',
                    diseño_cuali_udti:'',
                    diseño_cuali_na:'',
                    diseño_cuali_plazo:0,
                    diseño_cuali_fecha_inicio:'',
                    diseño_cuali_fecha_termino:'',

                    constru_marcomuestral_dya:'',
                    constru_marcomuestral_ucc:'',
                    constru_marcomuestral_udti:'',
                    constru_marcomuestral_na:'',
                    constru_marcomuestral_plazo:0,
                    constru_marcomuestral_fecha_inicio:'',
                    constru_marcomuestral_fecha_termino:'',

                    progra_plat_cuanti_dya:'',
                    progra_plat_cuanti_ucc:'',
                    progra_plat_cuanti_udti:'',
                    progra_plat_cuanti_na:'',
                    progra_plat_cuanti_plazo:0,
                    progra_plat_cuanti_fecha_inicio:'',
                    progra_plat_cuanti_fecha_termino:'',

                    progra_plat_cuali_dya:'',
                    progra_plat_cuali_ucc:'',
                    progra_plat_cuali_udti:'',
                    progra_plat_cuali_na:'',
                    progra_plat_cuali_plazo:0,
                    progra_plat_cuali_fecha_inicio:'',
                    progra_plat_cuali_fecha_termino:'',

                    programacion_cuestionario_dya:'',
                    programacion_cuestionario_ucc:'',
                    programacion_cuestionario_udti:'',
                    programacion_cuestionario_na:'',
                    programacion_cuestionario_plazo:0,
                    programacion_cuestionario_fecha_inicio:'',
                    programacion_cuestionario_fecha_termino:'',

                    def_equipo_cuanti_dya:'',
                    def_equipo_cuanti_ucc:'',
                    def_equipo_cuanti_udti:'',
                    def_equipo_cuanti_na:'',
                    def_equipo_cuanti_plazo:0,
                    def_equipo_cuanti_fecha_inicio:'',
                    def_equipo_cuanti_fecha_termino:'',

                    def_equipo_cuali_dya:'',
                    def_equipo_cuali_ucc:'',
                    def_equipo_cuali_udti:'',
                    def_equipo_cuali_na:'',
                    def_equipo_cuali_plazo:0,
                    def_equipo_cuali_fecha_inicio:'',
                    def_equipo_cuali_fecha_termino:'',

                    cap_pretest_cuanti_dya:'',
                    cap_pretest_cuanti_ucc:'',
                    cap_pretest_cuanti_udti:'',
                    cap_pretest_cuanti_na:'',
                    cap_pretest_cuanti_plazo:0,
                    cap_pretest_cuanti_fecha_inicio:'',
                    cap_pretest_cuanti_fecha_termino:'',

                    cap_pretest_cuali_dya:'',
                    cap_pretest_cuali_ucc:'',
                    cap_pretest_cuali_udti:'',
                    cap_pretest_cuali_na:'',
                    cap_pretest_cuali_plazo:0,
                    cap_pretest_cuali_fecha_inicio:'',
                    cap_pretest_cuali_fecha_termino:'',

                },
                segactividades:{
                    ejec_pretest_cuanti_dya:'',
                    ejec_pretest_cuanti_ucc:'',
                    ejec_pretest_cuanti_udti:'',
                    ejec_pretest_cuanti_na:'',
                    ejec_pretest_cuanti_plazo:0,
                    ejec_pretest_cuanti_fecha_inicio:'',
                    ejec_pretest_cuanti_fecha_termino:'',

                    ejec_pretest_cuali_dya:'',
                    ejec_pretest_cuali_ucc:'',
                    ejec_pretest_cuali_udti:'',
                    ejec_pretest_cuali_na:'',
                    ejec_pretest_cuali_plazo:0,
                    ejec_pretest_cuali_fecha_inicio:'',
                    ejec_pretest_cuali_fecha_termino:'',

                    base_pretest_dya:'',
                    base_pretest_ucc:'',
                    base_pretest_udti:'',
                    base_pretest_na:'',
                    base_pretest_plazo:0,
                    base_pretest_fecha_inicio:'',
                    base_pretest_fecha_termino:'',

                    revision_correccion_dya:'',
                    revision_correccion_ucc:'',
                    revision_correccion_udti:'',
                    revision_correccion_na:'',
                    revision_correccion_plazo:0,
                    revision_correccion_fecha_inicio:'',
                    revision_correccion_fecha_termino:'',

                    cap_oficial_cuanti_dya:'',
                    cap_oficial_cuanti_ucc:'',
                    cap_oficial_cuanti_udti:'',
                    cap_oficial_cuanti_na:'',
                    cap_oficial_cuanti_plazo:0,
                    cap_oficial_cuanti_fecha_inicio:'',
                    cap_oficial_cuanti_fecha_termino:'',

                    cap_oficial_cuali_dya:'',
                    cap_oficial_cuali_ucc:'',
                    cap_oficial_cuali_udti:'',
                    cap_oficial_cuali_na:'',
                    cap_oficial_cuali_plazo:0,
                    cap_oficial_cuali_fecha_inicio:'',
                    cap_oficial_cuali_fecha_termino:'',

                    convocatoria_reclutamiento_dya:'',
                    convocatoria_reclutamiento_ucc:'',
                    convocatoria_reclutamiento_udti:'',
                    convocatoria_reclutamiento_na:'',
                    convocatoria_reclutamiento_plazo:0,
                    convocatoria_reclutamiento_fecha_inicio:'',
                    convocatoria_reclutamiento_fecha_termino:'',

                    trabajo_campo_cuanti_dya:'',
                    trabajo_campo_cuanti_ucc:'',
                    trabajo_campo_cuanti_udti:'',
                    trabajo_campo_cuanti_na:'',
                    trabajo_campo_cuanti_plazo:0,
                    trabajo_campo_cuanti_fecha_inicio:'',
                    trabajo_campo_cuanti_fecha_termino:'',

                    trabajo_campo_cuali_dya:'',
                    trabajo_campo_cuali_ucc:'',
                    trabajo_campo_cuali_udti:'',
                    trabajo_campo_cuali_na:'',
                    trabajo_campo_cuali_plazo:0,
                    trabajo_campo_cuali_fecha_inicio:'',
                    trabajo_campo_cuali_fecha_termino:'',

                    supervision_dya:'',
                    supervision_ucc:'',
                    supervision_udti:'',
                    supervision_na:'',
                    supervision_plazo:0,
                    supervision_fecha_inicio:'',
                    supervision_fecha_termino:'',

                    creacion_bbdd_dya:'',
                    creacion_bbdd_ucc:'',
                    creacion_bbdd_udti:'',
                    creacion_bbdd_na:'',
                    creacion_bbdd_plazo:0,
                    creacion_bbdd_fecha_inicio:'',
                    creacion_bbdd_fecha_termino:'',

                    validacion_bbdd_dya:'',
                    validacion_bbdd_ucc:'',
                    validacion_bbdd_udti:'',
                    validacion_bbdd_na:'',
                    validacion_bbdd_plazo:0,
                    validacion_bbdd_fecha_inicio:'',
                    validacion_bbdd_fecha_termino:'',
                },
            }
        },
        mounted(){
            if(this.$route.params.id ==null){
                this.$router.push({ name: 'mostrarProyectos'});
            }
            else{
                this.cargarPresentacion()
                this.cargarOperaciones()
                this.cargarActividades()
                this.cargarSegactividades()
            }
        },
        methods:{
            // ---- Cargar datos de frop --------
            cargarPresentacion(){
                axios.get(`/api/presentacion/${this.$route.params.id}`)
                .then(response=>{
                    this.presentacion = response.data[0]
                })
                .catch(e=>{
                    console.log(e)
                })
            },
            cargarOperaciones(){
                axios.get(`/api/operaciones/${this.$route.params.id}`)
                .then(response=>{
                    this.operaciones = response.data[0]
                })
                .catch(e=>{
                    console.log(e)
                })
            },
            cargarActividades(){
                axios.get(`/api/actividades/${this.$route.params.id}`)
                .then(response=>{
                    this.actividades = response.data[0]
                })
                .catch(e=>{
                    console.log(e)
                })
            },
            cargarSegactividades(){
                axios.get(`/api/segactividades/${this.$route.params.id}`)
                .then(response=>{
                    this.segactividades = response.data[0]
                })
                .catch(e=>{
                    console.log(e)
                })
            },
        }
}
</script>