<template>
    <div class="container" style="margin-top:10px;">

        <div class="card" style="width: 80%; border:10px;">
        <div class="card-body">
            <form @submit.prevent="guardarProyecto">
                <label for="" >Nombre del proyecto</label>
                <input type="text" class="form-control" placeholder="Ingresar nombre..." v-model="proyecto.nombre_proyecto">

                <label for="">Código</label>
                <input type="text" class="form-control" placeholder="Ingresar código..." v-model="proyecto.codigo">

                <button class="btn btn-success" style="margin-top:5px;">Crear</button>   
            </form>
        </div>
        </div>

    </div>
    
</template>

<script>
export default {
    data () {
            return {
                proyecto:{
                    nombre_proyecto :'',
                    codigo:'',
                    estado:'Activo',
                },
                presentacion:{
                    id: '',
                    nombre_proyecto :'',
                },
                operaciones:{
                    id: '', 
                },
                actividades:{
                    id: '', 

                },
                segactividades:{
                    id: '', 

                },
            }
        },
        methods:{
            async guardarProyecto(){
                if(this.proyecto.nombre_proyecto != "" && this.proyecto.codigo != ""){
                    await axios.post('/api/proyecto', this.proyecto)
                    .then(responce=>{
                        Toast.fire({
                        icon: 'success',
                        title: 'Se ha guardado correctamente'
                        });
                        this.presentacion.nombre_proyecto = this.proyecto.nombre_proyecto
                        this.presentacion.id = this.proyecto.codigo
                        this.operaciones.id = this.proyecto.codigo
                        this.actividades.id = this.proyecto.codigo
                        this.segactividades.id = this.proyecto.codigo
                        this.guardarFrop()
                        this.$router.push({ name: 'mostrarProyectos'});
                    })
                    .catch(error=>{
                        console.log(error)
                    })
                }
                else{
                    Toast.fire({
                        icon: 'warning',
                        title: 'Se deben llenar todos los campos'
                        });
                }

            },
            guardarPresentacion(){
                axios.post('/api/presentacion', this.presentacion)
                .then(response=>{   
                })
                .catch(e=>{
                    console.log(e)
                })
            },
            guardarOperacion(){
                axios.post('/api/operaciones', this.operaciones)
                .then(response=>{
                })
                .catch(e=>{
                    console.log(e)
                })
            },
            guardarActividades(){
                axios.post('/api/actividades', this.actividades)
                .then(response=>{
                })
                .catch(e=>{
                    console.log(e)
                })
            },
            guardarSegactividades(){
                axios.post('/api/segactividades', this.segactividades)
                .then(response=>{
                })
                .catch(e=>{
                    console.log(e)
                })
            },
            guardarFrop(){
                this.guardarPresentacion()
                this.guardarOperacion()
                this.guardarActividades()
                this.guardarSegactividades()
            }

        },
}
</script>