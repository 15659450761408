<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">Gestión de Categorías</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-success float-right mt-1" @click="newModal">
                      <i class="fas fa-plus-circle mr-1"></i>Agregar
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nombre</th>
                      <th>Descripción</th>
                      <th>Estado</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="category in categories.data" :key="category.id">

                      <td>{{category.id}}</td>
                      <td class="text-capitalize">{{category.nombre}}</td>
                      <td>{{category.descripcion}}</td>
                      <td>
                        <div v-if="category.estado">
                            <span class="badge badge-success p-2">Activo</span>
                        </div>
                        <div v-else>
                            <span class="badge badge-danger p-2">Desactivado</span>
                        </div>
                      </td>
                      <td>{{category.created_at}}</td>
                      <td>
                        <a href="#" @click="editModal(category)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <template v-if="category.estado">
                            <a href="#" @click="desactivarCategoria(category.id)">
                                <i class="fas fa-times-circle red"></i>
                            </a>
                        </template>
                        <template v-else>
                            <a href="#" @click="desactivarCategoria(category.id)">
                                <i class="fas fa-check-circle green"></i>
                            </a>
                        </template>
                      </td>
                      <td>

                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="categories" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Agregar nueva categoría</h5>
                    <h5 class="modal-title" v-show="editmode">Actualizar Categoría</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateCategory() : createCategory()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Nombre</label>
                            <input v-model="form.nombre" type="text" name="nombre"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('nombre') }">
                            <has-error :form="form" field="nombre"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Descripción</label>
                            <input v-model="form.descripcion" type="text" name="descripcion"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('descripcion') }">
                            <has-error :form="form" field="descripcion"></has-error>
                        </div>
                        <div class="form-group">
                            <div v-if="!editmode">
                            </div>
                            <div v-else-if="editmode">
                            <label>Estado</label>
                              <select class="form-control" v-model="form.estado">
                                <option class="bg-green">Activo</option>
                                <option class="bg-red">Desactivado</option>
                              </select>
                            </div>
                            <!-- <has-error :form="form" field="estado"></has-error>
                            <label>Estado</label>
                            <input v-model="form.estado" type="text" name="estado"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('estado') }">
                            <has-error :form="form" field="estado"></has-error> -->
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Actualizar</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Crear</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                categories : {},
                form: new Form({
                    id : '',
                    nombre: '',
                    descripcion: '',
                    estado: ''
                })
            }
        },
        methods: {

            getResults(page = 1) {

                  this.$Progress.start();
                  
                  axios.get('/api/category?page=' + page).then(({ data }) => (this.categories = data.data));

                  this.$Progress.finish();
            },
            updateCategory(){
                this.$Progress.start();
                this.form.put('/api/category/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadCategories();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            desactivarCategoria(id){
                this.$Progress.start();
                axios.put('/api/category/desactivar/'+id)
                .then(() => {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadProducts();
                })
                .catch((error) => {
                    this.$Progress.fail();
                    console.log(error);
                });
            },
            editModal(category){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(category);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },

            loadCategories(){
                if(this.$gate.isAdmin()){
                    axios.get("/api/category").then(({ data }) => (this.categories = data.data));
                }
            },
            
            createCategory(){

                this.form.post('/api/category')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadCategories();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            }

        },
        mounted() {
            console.log('Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadCategories();
            this.$Progress.finish();
        }
    }
</script>
