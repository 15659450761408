<template>
        <div class="container">
        <br>
        <br>
        <table class="table table-striped">
            <thead >
                <tr>
                    <td>ID</td>
                    <td>Nombre</td>
                    <td>Código</td>
                    <td>Estado</td>
                    <td>Fecha creación</td>
                    <td>Opciones</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="proyecto in proyectos" :key="proyecto.id">
                    <td>{{ proyecto.id }}</td>
                    <td>{{ proyecto.nombre_proyecto }}</td>
                    <td>{{ proyecto.codigo }}</td>
                    <td>{{ proyecto.estado }}</td>
                    <td>{{ proyecto.created_at | myDate }}</td>
                    <td>
                        <router-link :to='{name:"verfrop", params:{id:proyecto.codigo}}' class="btn btn-info">Ver</router-link>
                        <router-link :to='{name:"mostrarfrop", params:{id:proyecto.codigo}}' class="btn btn-info" v-if="$gate.isAdmin()">Editar</router-link>
                    </td>
                </tr>
            </tbody>

        </table>
    </div>  
</template>

<script>
export default {
    name: "proyectos",
    data(){
        return{
            proyectos:[]
        }
    },
    mounted(){
        this.mostrarProyectos()
    },
    methods:{
         mostrarProyectos(){
            axios.get('/api/proyecto')
            .then(response=>{
                this.proyectos= response.data
            })
            .catch(error=>{
                this.proyectos =[]
            })
        }
    }
}
</script>