export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/registro-general', component: require('./components/RegistroGeneral.vue').default },
    { path: '/perfil', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default },
    { path: '/frop',name:'mostrarfrop', component: require('./components/frop/index.vue').default },
    { path: '/proyecto', component: require('./components/proyecto/index.vue').default },
    { path: '/mostrarProyectos',name:'mostrarProyectos', component: require('./components/frop/mostrar.vue').default },
    { path: '/verfrop',name:'verfrop', component: require('./components/frop/ver.vue').default },
    
];
